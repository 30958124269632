import React, {Component} from 'react';
import './App.scss';

class App extends Component {
  constructor() {
    super()
    global.app = this;

    this.state = {
      result: null
      // result: {"account":{"Biography":"📍薄着\nJapanese / Shanghai\n𝑩𝑶𝑶𝑲𝑺＝ 相澤義和 『愛情観察』 / Young ho seo 『𝒕𝒐𝒌𝒚𝒐 𝒈𝒊𝒓𝒍𝒔』\n𝑾𝑬𝑩＝『𝑵𝑬𝑼𝑻』2018.03.01 /『𝑫𝑨𝒁𝑬𝑫』 2019.05.24 / 『𝑻𝒐𝒌𝒚𝒐 𝒂𝒏𝒂𝒍𝒐𝒈』2020.01.05","External Url":"https://www.dazeddigital.com/art-photography/article/44222/1/ekaterina-bazhenova-yamasaki-photos-japanese-love-hotel-porn-set-female-desire","Full name":"meguro","Id":"318972780","Is private":false,"Is verified":false,"Number of followers":6850,"Number of follows":994,"Number of published posts":941,"Profile pic url":"https://scontent-nrt1-1.cdninstagram.com/v/t51.2885-19/s320x320/53735690_404663333432465_7686041046471409664_n.jpg?_nc_ht=scontent-nrt1-1.cdninstagram.com&_nc_ohc=7uZ2_AfIWV8AX8AYXxF&oh=9461fa20e79096a26342a30a78aac8e1&oe=5EEABB99","Username":"_meguro_"},"medias_count":100,"success":true,"total_likes":36272}
    }
  }

  componentDidMount() {

  }

  onResult(data) {
    // console.log(data)

    console.log(JSON.stringify(data))

    this.setState({result: data})
  }

  render() {
    const {result} = this.state
    return (
      <div className="App">
        <div className="container">
          <div className="title">
            INSTAMETER
          </div>

          {
            result
            ?
            <Result result={result}></Result>
            :
            <Input></Input>
          }


        </div>
      </div>
      )
  }
}

class Input extends Component {
  constructor() {
    super()

    this.username = ''
    this.state = {
      is_analyzing: false
    }
  }

  onChange(e) {
    const value = e.target.value
    this.username = value
  }

  onClick(e) {
    this.setState({is_analyzing: true})

    fetch('http://153.126.151.184:5000/meter/' + this.username)
      .then(res => res.json())
      .then(data => {
        this.setState({is_analyzing: false})

        if (data.success) {
          global.app.onResult(data)
        } else {
          alert('存在しないアカウントです')
        }

      })
  }

  render() {
    const {is_analyzing} = this.state;

    return (
      <div className="Input">
        <div className="input-field col s6">
          <input placeholder="アカウント名" type="text" className="validate" onChange={this.onChange.bind(this)} />
        </div>
        <div className="btn" onClick={this.onClick.bind(this)}><i className="material-icons left">tag_faces</i>CHECK INFLUENCER POWER</div>      
        {
          is_analyzing
          ?
          <span className="analyzing">Analyzing...</span>
          :
          null
        }
      </div>
      );
  }
}

class Result extends Component {
  render() {
    const {result} = this.props;
    const account = result.account;

    console.log(result)

    const table_content = [
      ["Full name", 'フルネーム'],
      ["Biography", "自己紹介"],
      ["External Url", "URL"],
      ["Id", "ユーザーID"],
      // ["Is verified", "公式アカウント"],
      ["Number of followers", "フォロワー数"],
      ["Number of follows", "フォロー数"],
      ["Number of published posts", "投稿数"],
      // ["Profile pic url", "プロフィール画像"],
      // ["Username", 'ユーザー名'],
    ]

    const user_name = account["Username"]
    const profile_image_url = account["Profile pic url"]
    const is_varified = account["Is verified"]
    const follower_count = account["Number of followers"]
    const post_count = account["Number of published posts"]

    const like_rate = result.total_likes / result.medias_count

    return (
      <div className="Result">
        <div className="row">
          <div className="left col s12 m8">
            <div className="username">{user_name}</div>
            <div className="user_data">
              <span className="title">フォロワー数</span>
              <span className="value">{follower_count}</span>
            </div>
            <div className="user_data">
              <span className="title">投稿数</span>
              <span className="value">{post_count}</span>
            </div>
            <div className="user_data">
              <span className="title">いいね率</span>
              <span className="value">{like_rate} / 投稿</span>
            </div>

            <div className="close-button btn red darken-2" onClick={() => {
              global.app.setState({result: null})
            }}>CLOSE</div>
          </div>

          <div className="right col s12 m4">
            <div className="profile_image">
              <img src={profile_image_url} alt=""/>
            </div>
          </div>
        </div>
        <table>
          <tbody>
            { table_content.map(([key, name], i) =>
              <tr key={i}>
                <td>{name}</td>
                <td>{account[key]}</td>
              </tr>
              )
            }
          </tbody>
        </table>
      </div>
      )
  }
}

export default App;
